<template>
  <form ref="form" class="list-filter entity-list-filter">
    <div class="filter-input" v-if="filters">
      <lims-select
        v-model="filters.entityTypeIds"
        :options="dataSource.entityTypeList"
        :placeholder="$t('pages/entity/list/entityListFilter/entityTypePlaceholder')"
      ></lims-select>
      <lims-select
        v-model="filters.clinicTypeIds"
        :options="dataSource.clinicTypeList"
        :translated="true"
        :placeholder="$t('pages/entity/list/entityListFilter/clinicTypesPlaceholder')"
      ></lims-select>
      <lims-select
        v-model="filters.entityStatusIds"
        :options="dataSource.entityStatusList"
        :placeholder="$t('global/placeholder.allStatus')"
      ></lims-select>

      <md-field class="input-search">
        <md-icon>search</md-icon>
        <md-input
          v-model="filters.search"
          :placeholder="$t('pages/entity/list/entityListFilter/searchPlaceholder')"
          @keyup.enter="onSearch"
          maxlength="250"
        ></md-input>
      </md-field>
    </div>
    <lims-chip-area :options="chipOptions" @onRemoveChip="$onRemoveChip"></lims-chip-area>
    <div class="filter-action">
      <md-button @click="$onClearFilter">
        {{ $t('global/button/button.clear') }}
      </md-button>
    </div>
  </form>
</template>
<script>
/* eslint-disable security/detect-object-injection */
import { ConfigMixins, ListFilterMixins } from '@/core/mixins';
import LimsChipArea from '@/components/Lims/LimsChipArea.vue';
import LimsSelect from '@/components/Lims/LimsSelect.vue';

import { ENTITY_TYPE_ENUMS, ENTITY_STATUSES_ENUM, DROPDOWN_SHORT_NAME } from '@/core/constants';
import { DropdownService } from '@/services';

export default {
  mixins: [ConfigMixins, ListFilterMixins],
  components: { LimsChipArea, LimsSelect },
  props: {
    fields: {
      type: Array,
      require: true,
    },
    defaultValues: {
      type: Object,
      require: true,
    },
    value: {
      type: Object,
      require: true,
    },
  },
  async created() {
    this.onCreated();
  },
  computed: {},
  data() {
    return {
      filters: null,
      dataSource: {
        entityTypeList: [],
        clinicTypeList: [],
        entityStatusList: [],
      },
      chipOptions: [],
    };
  },
  watch: {
    filters: {
      handler(filterValues) {
        this.$updateChipList({ filterValues, dataSource: this.dataSource, filterFields: this.fields });
      },
      deep: true,
    },
  },
  methods: {
    async onCreated() {
      this.dataSource = await this.fetchDataSource();
      this.filters = this.value;
    },
    async fetchDataSource() {
      // load and binding datasource
      const clinicTypeList = await this.loadClinicTypes();
      const entityTypeList = this.loadEntityTypes();
      const entityStatusList = this.loadEntityStatus();
      return {
        clinicTypeList,
        entityTypeList,
        entityStatusList,
      };
    },

    async loadClinicTypes() {
      const { data } = await DropdownService.getDropdownByShortName(DROPDOWN_SHORT_NAME.CLINIC_TYPE);
      return data.map((item) => {
        return {
          value: item.fieldItemId,
          label: item.fieldItemName,
        };
      });
    },
    loadEntityTypes() {
      return ENTITY_TYPE_ENUMS;
    },
    loadEntityStatus() {
      return ENTITY_STATUSES_ENUM;
    },
  },
};
</script>
