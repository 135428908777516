<template>
  <div>
    <md-table class="paginated-table table-striped table-hover lims-table user-tbl">
      <md-table-toolbar>
        <slot name="toolbar"></slot>
      </md-table-toolbar>
      <div class="table-fix-header">
        <thead>
          <md-table-row>
            <md-table-head v-for="(col, index) in colTabl" :key="index" :class="col.class">
              <div @mouseover="hoverIn(index)" @mouseout="hoverOut()" @click="sortCol(col.key, col.sort)">
                {{ $t(col.name) }}
                <span>
                  <md-icon v-if="col.sort">
                    {{
                      col.key === orderState.orderField
                        ? orderState.orderBy === 'asc'
                          ? 'arrow_drop_up'
                          : 'arrow_drop_down'
                        : hover === index
                        ? 'arrow_drop_up'
                        : ''
                    }}
                  </md-icon>
                </span>
              </div>
            </md-table-head>
          </md-table-row>
        </thead>
        <tbody>
          <md-table-row
            v-for="(item, idx) in entities"
            :key="`${item.entityId}-${idx}`"
            v-long-press="300"
            @click.native="onNavigate($event, item)"
            @long-press-start="onPlusStart"
            @long-press-stop="onPlusStop"
          >
            <md-table-cell>{{ item.entityName }}</md-table-cell>
            <md-table-cell v-if="isDisplayClinicType">{{ item.entityTypeName }}</md-table-cell>
            <md-table-cell>{{ item.clinicTypeName }}</md-table-cell>
            <md-table-cell>{{ item.email }}</md-table-cell>
            <md-table-cell class="statusName-column">
              <md-icon
                :class="[
                  { 'status-enabled': item.statusId == ENTITY_STATUSES.Enabled },
                  { 'status-disabled': item.statusId == ENTITY_STATUSES.Disabled },
                ]"
                >adjust</md-icon
              >
            </md-table-cell>
            <md-table-cell class="cell-action">
              <div class="cell-action-inner">
                <div style="width: 33%">
                  <md-button
                    v-if="isShowViewIcon(item)"
                    class="md-just-icon md-info md-simple"
                    @click.stop="handleAction('view', item)"
                  >
                    <md-icon>visibility</md-icon>
                  </md-button>
                </div>
                <div style="width: 33%">
                  <md-button
                    v-if="isShowEditIcon(item)"
                    class="md-just-icon md-warning md-simple"
                    @click.stop="handleAction('edit', item)"
                  >
                    <md-icon>edit</md-icon>
                  </md-button>
                </div>
              </div>
            </md-table-cell>
          </md-table-row>
        </tbody>
      </div>
    </md-table>
  </div>
</template>

<script>
import { ENTITY_TYPES, ENTITY_STATUSES } from '@/core/constants';
export default {
  name: 'entity-list',
  created() {
    this.orderState = this.sort;
  },

  props: {
    sort: {
      type: Object,
      require: true,
    },
    entities: {
      required: false,
      default: function () {
        return [];
      },
    },
  },
  data() {
    return {
      longPressMode: false,
      plusInterval: null,
      colTabl: [
        {
          name: 'pages/entity/list/list.name',
          key: 'entityName',
          sort: true,
          sortType: 'asc',
          class: 'entityName-column',
        },
        {
          name: 'pages/entity/list/list.entity',
          key: 'entity',
          sort: false,
          class: 'entity-column',
        },
        {
          name: 'pages/entity/list/list.clinicType',
          key: 'clinicType',
          sort: false,
          class: 'clinicType-column',
        },
        {
          name: 'pages/entity/list/list.email',
          key: 'email',
          sort: false,
          class: 'email-column',
        },
        {
          name: 'pages/entity/list/list.status',
          key: 'status',
          sort: false,
          class: 'statusName-column',
        },
        {
          name: 'global/pages/list.actions',
          key: 'action',
          sort: false,
          class: 'actions-column',
        },
      ],
      hover: '',
      orderState: {
        orderField: 'entityName',
        orderBy: 'asc',
      },
    };
  },
  computed: {
    ENTITY_STATUSES: function () {
      return ENTITY_STATUSES;
    },
  },
  watch: {
    orderState: {
      handler() {
        let sortType = this.orderState.orderField + ':' + this.orderState.orderBy;
        this.$emit('sort', sortType);
      },
      deep: true,
    },
  },
  methods: {
    handleAction(action, item) {
      this.$emit('action', {
        action,
        item,
      });
    },

    onNavigate(e, item) {
      if (!this.longPressMode) {
        e.preventDefault();
        this.handleAction('view', item);
      }
    },
    onPlusStart() {
      this.plusInterval = setInterval(() => {
        this.longPressMode = true;
      }, 100);
    },

    onPlusStop() {
      setTimeout(() => {
        this.longPressMode = false;
      }, 500);
      clearInterval(this.plusInterval);
    },

    hoverIn(index) {
      this.hover = index;
    },

    hoverOut() {
      this.hover = '';
    },

    sortCol(colName, isSort) {
      if (isSort) {
        if (this.orderState.orderField === colName) {
          if (this.orderState.orderBy === 'asc') {
            this.orderState.orderBy = 'desc';
          } else {
            this.orderState.orderBy = 'asc';
          }
        } else {
          this.orderState.orderField = colName;
          this.orderState.orderBy = 'asc';
        }
      }
    },

    isDisplayClinicType() {
      return (
        this.entities.entityTypeId === ENTITY_TYPES.Clinic ||
        this.entities.entityTypeId === ENTITY_TYPES.ClinicLaboratory
      );
    },

    isShowViewIcon(item) {
      return (
        this.$isAuthorized(['Accounts_EntityManagement_Retrieve']) ||
        (this.$isAuthorized(['Accounts_EntityManagement_Update']) && item.statusId === ENTITY_STATUSES.Disabled)
      );
    },

    isShowEditIcon(item) {
      return this.$isAuthorized(['Accounts_EntityManagement_Update']) && item.statusId === ENTITY_STATUSES.Enabled;
    },
  },
};
</script>
<style lang="scss"></style>
