<template>
  <lims-block :cardText="false" class="list-page">
    <modal-select-entity-type
      ref="selectEntityTypeModal"
      slot="blockTitle"
      @onSelect="onSelectEntityType"
    ></modal-select-entity-type>
    <div class="md-layout" slot="blockContent">
      <div class="md-layout-item" v-if="query">
        <entity-list :entities="items" @action="onAction" :sort="query.sort">
          <md-table-toolbar slot="toolbar">
            <div class="toolbar-row">
              <div v-if="$isAuthorized(['Accounts_EntityManagement_Create'])" class="md-toolbar-section-end">
                <md-button @click="onAddEntity" class="btn-addUser">
                  {{ $t('pages/entity/list/button.addEntity') }}
                </md-button>
              </div>
            </div>
            <div class="toolbar-row">
              <div class="field-rows-per-page">
                <lims-row-per-page :pageSize="query.pagination.pageSize" @pageSizeChanged="$pageSizeChanged" />
              </div>
              <div class="field-search">
                <EntityListFilter
                  v-model="query.filters"
                  :defaultValues="defaultValues"
                  :fields="fields"
                  @onResetFilters="onResetFilters"
                />
              </div>
            </div>
            <lims-pagination ref="pager" :total-item="totalItem" :pagination="query.pagination" />
          </md-table-toolbar>
        </entity-list>
        <lims-pagination :total-item="totalItem" :pagination="query.pagination" />
      </div>
    </div>
  </lims-block>
</template>

<script>
import { ModalSelectEntityType } from '@/components/Lims/modals';
import LimsRowPerPage from '@/components/Lims/LimsRowPerPage';
import EntityListFilter from '@/pages/Entity/List/EntityListFilter';
import EntityList from '@/pages/Entity/List/EntityList';
import LimsPagination from '@/components/Lims/LimsPagination';
import { ConfigMixins, ListWithFilterMixins } from '@/core/mixins';
import { APP_ROUTES, ENTITY_TYPE_VALUES, FORM_ACTIONS } from '@/core/constants';
import cloneDeep from 'lodash/cloneDeep';
import { buildFilterFromQuery, buildFilterQueryParams } from '@/query/utils';
import { entityQuery } from '@/query';
import { EntityService } from '@/services';

export default {
  name: 'LimsEntity',
  components: {
    ModalSelectEntityType,
    LimsRowPerPage,
    EntityListFilter,
    EntityList,
    LimsPagination,
  },
  mixins: [ConfigMixins, ListWithFilterMixins],
  created() {
    this.initState();
  },
  data() {
    return {
      items: [],
      totalItem: null,
      query: null,
    };
  },
  computed: {
    fields() {
      return entityQuery.filters.fields;
    },
    defaultValues() {
      return entityQuery.filters.defaultValues;
    },
  },
  methods: {
    // common methods
    initState() {
      const queryParams = this.$route.query;
      const filters = buildFilterFromQuery(entityQuery.filters, queryParams);
      const pagination = buildFilterFromQuery(entityQuery.pagination, {
        pageSize: this.$appConfig.itemPerPageDefault,
        ...queryParams,
      });
      const sort = buildFilterFromQuery(entityQuery.sort, queryParams);
      const query = { filters, pagination, sort };
      this.query = query;
    },
    async search(queryObject) {
      const { error, data } = await EntityService.search({
        filters: buildFilterQueryParams(queryObject.filters),
        pagination: buildFilterQueryParams(queryObject.pagination),
        sort: `${queryObject.sort.orderField}:${queryObject.sort.orderBy}`,
      });
      // detect error and show alert to user
      if (error) {
        this.$alertError(error);
        // remove loading screen and allow user to interact
        return;
      }

      // binding data
      this.items = data.items;
      this.totalItem = data.pagination.totalItems;

      // update router
      const query = {
        ...buildFilterQueryParams(queryObject.filters),
        ...buildFilterQueryParams(queryObject.pagination),
        ...buildFilterQueryParams(queryObject.sort),
      };

      this.$router
        .push({
          query,
        })
        .catch((error) => {
          if (
            error.name === 'NavigationDuplicated' &&
            error.message.includes('Avoided redundant navigation to current location')
          ) {
            history.pushState({}, '', new URL(new URL(window.location)));
          }
        });
    },

    onResetFilters() {
      this.query.filters = cloneDeep(entityQuery.filters.defaultValues);
    },
    // event handles - start with on prefix
    onAddEntity() {
      this.$refs.selectEntityTypeModal.open();
    },

    // eslint-disable-next-line no-unused-vars
    onSelectEntityType(formData) {
      // navigate
      if (formData.selectOption) {
        this.$router.push({
          name: 'EntityManagementAdd',
          params: {
            entityType: ENTITY_TYPE_VALUES[formData.selectOption.value - 1],
          },
        });

        this.$refs.selectEntityTypeModal.close();
      }
    },
    editRow(item) {
      this.$router.push(`${APP_ROUTES.ENTITY_EDIT}/${item.entityId}`);
    },
    viewRow(item) {
      this.$router.push(`${APP_ROUTES.ENTITY_VIEW}/${item.entityId}`);
    },
    onAction({ action, item }) {
      if (action === FORM_ACTIONS.EDIT) {
        this.editRow(item);
      }
      if (action === FORM_ACTIONS.VIEW) {
        this.viewRow(item);
      }
      if (action === FORM_ACTIONS.DELETE) {
        this.deleteRow();
      }
    },
  },
};
</script>

<style lang="scss" scoped></style>
